<template>
	<v-sheet class="holiday-main-listing" style="height: calc(100vh - 88px)">
		<v-layout class="flex-wrap">
			<v-flex class="py-0">
				<!-- <v-select
					v-model="status"
					class="listing-select"
					hide-details
					:disabled="pageLoading"
					:items="statusLists"
					item-text="text"
					item-value="value"
					label="All Holiday"
					solo
					v-on:change="statusFilterRows($event)"
				>
					<template v-slot:item="{ item }">
						<v-list-item-content>
							<v-list-item-title v-text="item.text"></v-list-item-title>
							<v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
						</v-list-item-content>
						<v-list-item-action>
							<v-avatar size="26" :color="item.status_color" :text-color="item.textcolor">
								<span>{{ item.status_count }}</span>
							</v-avatar>
						</v-list-item-action>
					</template>
				</v-select> -->
			</v-flex>
			<v-spacer></v-spacer>
			<v-flex class="py-0 my-auto d-flex justify-content-end listing-right">
				<template v-if="selected.length > 0">
					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon left>mdi-chevron-down</v-icon>
								Bulk Action
							</v-btn>
						</template>
						<v-list class="py-0">
							<template v-for="(item, i) in listingBulkAction">
								<v-list-item link :key="i" v-on:click="doAction(item.value)">
									<v-list-item-title
										><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
										>{{ item.title }}</v-list-item-title
									>
								</v-list-item>
								<v-divider v-if="listingBulkAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
							</template>
						</v-list>
					</v-menu>
					<v-btn
						depressed
						:disabled="pageLoading"
						tile
						v-on:click="clearSelection"
						color="red lighten-1"
						class="text-white"
					>
						<v-icon left> mdi-close </v-icon>
						Clear Selections
					</v-btn>
				</template>
				<template v-else>
					<div class="my-1">
						<v-flex class="d-flex justify-content-end mr-1">
							<!-- <div class="mr-2 fw-600 my-auto">Filter By</div> -->
							<!-- <div class="filterTagSelect ml-3">
								<v-autocomplete
									v-model="filter_label"
									:items="labelItems"
									:menu-props="{ bottom: true, offsetY: true }"
									prepend-inner-icon="mdi-circle"
									:color="filter_label == 'High' ? 'red' : filter_label == 'Medium' ? 'orange' : 'blue'"
									:class="
										filter_label == 'High'
											? 'red-icon'
											: filter_label == 'Medium'
											? 'orange-icon'
											: 'blue-icon'
									"
									outlined
									hide-details
									class="filterSelect"
									content-class="filterSelectContent"
								>
									<template v-slot:item="data">
										<template>
											<v-list-item-icon class="mr-0 my-1">
												<v-icon
													class="mdi-18px"
													:color="data.item == 'High' ? 'red' : data.item == 'Medium' ? 'orange' : 'blue'"
													>mdi-circle</v-icon
												>
											</v-list-item-icon>
											<v-list-item-content>
												<v-list-item-title v-html="data.item"></v-list-item-title>
											</v-list-item-content>
										</template>
									</template>
								</v-autocomplete>
							</div> -->
							<!-- <div class="filterTagSelect ml-3">
								<v-autocomplete
									v-model="filter_user"
									:items="userItems"
									:menu-props="{ bottom: true, offsetY: true }"
									prepend-inner-icon="mdi-filter"
									outlined
									hide-details
									class="filterSelect"
									content-class="filterSelectContent"
								>
								</v-autocomplete>
							</div> -->
						</v-flex>

						<v-btn
							v-if="
								(login_user &&
									(login_user.users_type == 'super_admin' ||
										login_user.role == 1 ||
										login_user.role == 4)) ||
								(login_user && login_user.is_master == 1)
							"
							depressed
							tile
							color="blue darken-4"
							class="text-white"
							@click="createHoliday()"
						>
							<v-icon left> mdi-plus </v-icon>
							Create
						</v-btn>
						<!-- <v-btn
							depressed
							:disabled="pageLoading"
							color="blue darken-4"
							class="text-white"
							tile
							v-on:click="routeSetting()"
						>
							<v-icon>mdi-cog</v-icon>
						</v-btn>
						<v-btn
							depressed
							:disabled="pageLoading"
							color="blue darken-4"
							class="text-white"
							tile
							v-on:click="drawerFilter = true"
						>
							<v-icon>mdi-filter</v-icon>
						</v-btn> -->
						<v-menu
							bottom
							left
							origin="center center"
							transition="slide-y-transition"
							rounded="0"
							offset-y
							max-height="400px"
							:close-on-content-click="false"
							content-class="white-background"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									depressed
									color="blue darken-4"
									class="text-white"
									tile
									:disabled="pageLoading"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon>mdi-menu</v-icon>
								</v-btn>
							</template>
							<v-list class="py-0">
								<template v-for="(item, i) in listingAction">
									<v-list-item link :key="i" v-on:click="doAction(item.value)">
										<v-list-item-title
											><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
											>{{ item.title }}</v-list-item-title
										>
									</v-list-item>
									<v-divider v-if="listingAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
								</template>
							</v-list>
						</v-menu>

						<v-menu
							bottom
							left
							origin="center center"
							transition="slide-y-transition"
							rounded="0"
							offset-y
							max-height="400px"
							:close-on-content-click="false"
							content-class="white-background"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									depressed
									color="blue darken-4"
									class="text-white"
									tile
									:disabled="pageLoading"
									v-bind="attrs"
									v-on="on"
								>
									<v-icon>mdi-table-edit</v-icon>
								</v-btn>
							</template>
							<Draggable
								tag="ul"
								v-model="draggableThead"
								class="draggable-group"
								handle=".draggable-drag-icon"
								v-on:change="updateTable('member')"
							>
								<template v-for="cols in draggableThead">
									<li class="draggable-group-item" :key="cols.key" v-if="!cols.checkbox">
										<v-checkbox
											dense
											v-model="draggableTheadShow"
											v-bind:value="cols.key"
											:label="cols.name"
											:disabled="cols.fixed || pageLoading"
											color="blue"
											hide-details
											class="mt-0 mb-0"
											v-on:change="updateTableVisiblity('member')"
										></v-checkbox>
										<v-icon
											v-if="!pageLoading && !cols.fixed"
											class="draggable-action draggable-drag-icon"
											right
											color="blue"
											>mdi-drag</v-icon
										>
										<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
									</li>
								</template>
							</Draggable>
						</v-menu>
					</div>
				</template>
			</v-flex>
		</v-layout>
		<SearchCriteria
			v-if="search_Enabled()"
			:search-fields="listingFilter"
			search-string="Holiday #, Title, Location, Holiday Name, Start Date, End Date and Added At"
			v-on:advance-search="advanceSearch = false"
			v-on:close-search="resetSearch"
		></SearchCriteria>
		<template>
			<Table
				:page-loading="pageLoading"
				type="holiday"
				delete-endpoint="holiday/"
				is-drawer-detail
				v-on:open-drawer="open_drawer($event)"
				v-on:reload:content="filterRows"
			></Table>
		</template>
		<CreateHoliday
			:holiday-data="editHoliday"
			:drawer="create_holiday"
			v-on:close="create_holiday = false"
			v-on:refreshHolidayList="getListing()"
		></CreateHoliday>
		<AdvanceFilter
			:advanceSearch="drawerFilter"
			v-on:close="drawerFilter = false"
			:btx-query.sync="btxQuery"
			search-endpoint="holiday"
			search-title="Holiday"
		></AdvanceFilter>
	</v-sheet>
</template>
<script>
import ListingMixin from "@/core/mixins/listing.mixin";
import CreateHoliday from "@/view/module/holiday/CreateHoliday";
import AdvanceFilter from "@/view/pages/leads/AdvanceFilter";
import { SET_TBODY } from "@/core/services/store/listing.module";
import { GET, QUERY } from "@/core/services/store/request.module";
import { getCurrentUser } from "@/core/services/jwt.service";
import objectPath from "object-path";

export default {
	name: "holiday-listing",
	title: "Listing Holiday",
	mixins: [ListingMixin],
	data() {
		return {
			drawerFilter: false,
			confirm_dialog: false,
			editHoliday: {},
			holidayLists: [],
			filter_tag: "Software",
			labelItems: ["High", "Medium", "Low"],
			filter_label: "Medium",
			userItems: ["Everyone"],
			filter_user: "Everyone",
			create_holiday: false,
			pageTitle: "Holiday",
			pageBreadcrumbs: [{ text: "Holiday", disabled: true }],
			endpoint: "holiday",
			statusLists: [{ value: "all", text: "All Holiday" }],
			defaultFilter: {},
			totalRows: 0,
			rowsOffset: 0,
			status: "all",
			totalPages: 0,
			showingFrom: 0,
			showingTo: 0,
			login_user: null,
		};
	},
	components: {
		CreateHoliday,
		AdvanceFilter,
	},
	methods: {
		currentUserData() {
			const user = getCurrentUser();
			if (user) {
				this.login_user = user;
				if (!objectPath.get(user, "users_type")) {
					this.login_user = { ...user, users_type: "super_admin" };
					//console.log(this.login_user);
				}
			}
		},
		dataFilter(value) {
			this.addvanceFilterAarry = value;
		},
		createHoliday() {
			this.create_holiday = true;
			this.editHoliday = {};
		},
		routeSetting() {
			this.$router.push({
				name: "setting",
				query: {
					tab: "holiday",
				},
			});
		},
		open_drawer(id) {
			this.create_holiday = true;
			this.$store
				.dispatch(GET, { url: `single-holiday/${id}` })
				.then((data) => {
					this.editHoliday = data;
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		/* getHolidayStatus() {
			this.$store
				.dispatch(GET, { url: "holiday-setting" })
				.then((data) => {
					this.statusLists = data.status;
				})
				.catch((error) => {
					console.log({ error });
				})
				.finally(() => {
					this.pageLoading = false;
				});
		}, */
		statusFilterRows(value) {
			this.$store
				.dispatch(QUERY, {
					url: `holiday`,
					data: {
						status: value,
						all: 1,
					},
				})
				.then((data) => {
					this.$store.commit(SET_TBODY, data.tbody);
					this.addvanceFilterAarry = [];
				})
				.catch((error) => {
					console.log({
						error,
					});
				});
		},
	},
	mounted() {
		this.currentUserData();
		//this.getHolidayStatus(3);
	},
};
</script>
