<template>
	<div>
		<v-navigation-drawer v-model="drawer" fixed width="600" right temporary stateless>
			<div class="drawer-wrapper">
				<v-flex class="py-3 px-5 d-flex border-bottom drawer-header">
					<div class="font-level-3-bold">{{ getTitle() }} Holiday</div>
					<v-spacer></v-spacer>
					<v-btn depressed tile class="mr-2" v-on:click="$emit('close', true)">Cancel</v-btn>
					<v-btn
						tile
						depressed
						color="white--text"
						class="blue darken-4"
						:disabled="pageLoading || !formValid"
						v-on:click="update_or_create()"
						>Save</v-btn
					>
				</v-flex>
				<v-form
					ref="form"
					id="form"
					v-model.trim="formValid"
					lazy-validation
					v-on:submit.stop.prevent="update_or_create"
				>
					<div class="drawer-content pt-5 px-5">
						<div class="mb-3">
							<div class="d-flex pt-1">
								<label class="fw-500 required">Date</label>
							</div>
							<DatePicker
								depressed
								placeholder="Date"
								v-model="holiday.date"
								:disabled="pageLoading"
								:loading="pageLoading"
								:rules="[vrules.required(holiday.date, 'Date')]"
								:class="{
									required: !holiday.date,
								}"
								enjoyWeekEnd
							></DatePicker>
						</div>
						<div class="mb-3">
							<div class="d-flex pt-1">
								<label class="fw-500 mb-3 required">Description</label>
							</div>
							<v-textarea
								depressed
								hide-details
								outlined
								row-height="25"
								placeholder="Description"
								v-model="holiday.description"
								:disabled="pageLoading"
								:loading="pageLoading"
								:rules="[vrules.required(holiday.description, 'Description')]"
								:class="{
									required: !holiday.description,
								}"
							></v-textarea>
						</div>

						<div class="mb-3">
							<div class="d-flex pt-1">
								<v-radio-group v-model="holiday.type" row class="ma-0" hide-details>
									<v-radio label="Full Day" :value="1" class="pr-5"></v-radio>
									<v-radio label="Half Day" :value="2"></v-radio>
								</v-radio-group>
							</div>
						</div>
						<div class="mb-3">
							<div class="d-flex pt-1">
								<v-radio-group v-model.trim.number="holiday.working" row class="ma-0" hide-details>
									<v-radio label="Working" :value="1" class="pr-5"></v-radio>
									<v-radio label="Non Working" :value="2"></v-radio>
								</v-radio-group>
							</div>
						</div>
					</div>
				</v-form>
			</div>
		</v-navigation-drawer>
	</div>
</template>

<script>
import { POST, PUT } from "@/core/services/store/request.module";
import { SET_MESSAGE, SET_ERROR } from "@/core/services/store/common.module";
import { mapGetters } from "vuex";
import MainMixin from "@/core/mixins/main.mixin.js";
import DatePicker from "@/view/components/DatePicker";

export default {
	mixins: [MainMixin],
	name: "CreateHoliday",
	props: {
		drawer: {
			type: Boolean,
			default: false,
		},
		holidayData: {
			type: Object,
		},
	},
	components: {
		DatePicker,
	},
	watch: {
		holidayData: {
			handler(param) {
				//console.log(param);
				this.holiday.date = param.date;
				this.holiday.description = param.description;
				this.holiday.type = param.type;
				this.holiday.working = param.working;
			},
		},
	},
	data() {
		return {
			formValid: true,
			pageLoading: false,
			today: new Date(),
			halfday: false,
			from_date_dialog: false,
			end_date_dialog: false,
			holiday: {
				date: new Date().toISOString().substr(0, 10),
				description: null,
				type: 1,
				working: 1,
			},
		};
	},
	methods: {
		getTitle() {
			if (this.holidayData?.id) {
				return "Update";
			}

			this.holiday.type = 1;
			this.holiday.working = 2;
			return "Create";
		},
		update_or_create() {
			const formErrors = this.validateForm(this.$refs["form"]);

			this.$refs["form"].validate();

			if (formErrors.length) {
				this.$store.commit(SET_ERROR, this.errors.concat(formErrors));
				return false;
			}

			if (!this.$refs["form"].validate()) {
				return false;
			}

			if (this.holidayData && this.holidayData.id) {
				this.updateHoliday();
			} else {
				this.createHoliday();
			}
		},
		createHoliday() {
			this.pageLoading = true;

			this.$store
				.dispatch(POST, {
					url: `holiday`,
					data: this.holiday,
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: "Success ! Holiday added successfully.",
						},
					]);
					this.$refs.form.reset();
					this.$emit("close", true);
					this.$emit("refreshHolidayList", true);
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		updateHoliday() {
			this.pageLoading = true;

			this.$store
				.dispatch(PUT, {
					url: `holiday/${this.holidayData.id}`,
					data: this.holiday,
				})
				.then(() => {
					this.$store.commit(SET_MESSAGE, [
						{
							model: true,
							message: "Success ! Holiday updated successfully.",
						},
					]);
					this.$refs.form.reset();
					this.$emit("close", true);
					this.$emit("refreshHolidayList", true);
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	mounted() {},
	computed: {
		...mapGetters(["errors"]),
	},
};
</script>

<style scoped></style>
