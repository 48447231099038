import { render, staticRenderFns } from "./CreateHoliday.vue?vue&type=template&id=2f430b16&scoped=true&"
import script from "./CreateHoliday.vue?vue&type=script&lang=js&"
export * from "./CreateHoliday.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2f430b16",
  null
  
)

export default component.exports